/* Helper Class */
.bg-black-opacity-80 {
    background-color: rgba(0, 0, 0, 0.8);
}

.card {
    border-radius: 30px;
}

.card.white-card {
    background-color: #ffffff9e;
    border-radius: 20px;
    color: #000000;
}

.card.black-card {
    background-color: #000000b8;
    border-radius: 20px;
    color: #FFFFFF;
}

.scrollable-table-container {
    border-radius: 10px;
    font-size: 1rem;
    position: relative;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
}

table.scrollable-table {
    border: none !important;
    table-layout: auto !important;
    width: 100%;
}

table.scrollable-table tr {
    scroll-snap-align: start;
}

table.scrollable-table thead,
table.scrollable-table thead th {
    background-color: #000000;
    border: none;
    color: #ffffff;
    font-size: 0.875em;
    font-weight: 400;
    padding: 0.625em 0.375em;
    position: sticky;
    text-align: center;
    text-transform: uppercase;
    top: 0;
}

table.scrollable-table tbody > * + * {
    border-top: 1px solid white;
}

table.scrollable-table tbody td:first-child,
table.scrollable-table tbody td:nth-child(5n){
    background-color: #f7fafcd9;
}

table.scrollable-table tbody td:nth-child(2n){
    background-color: #edf2f7d9;
}

table.scrollable-table tbody td:nth-child(3n){
    background-color: #e2e8f0d9;
}

table.scrollable-table tbody td:nth-child(4n){
    background-color: #ffffffd9;
}

table.scrollable-table tbody td {
    border: none;
    /*font-size: 0.875em;*/
    line-height: 20px;
    padding: 0.375em;
    width: auto;
    white-space: wrap;
}

table.scrollable-table tr:has(> td.react-bs-table-no-data) {
    display: table-row;
}

table.scrollable-table td.react-bs-table-no-data {
    display: table-cell;
    font-size: 1em;
    font-weight: 600;
    padding: 0.75em 0.5em;
    width: 100%;
}


table.building-showcase-table td {
    background-color: #E6E6E6;
}

.adlinks-info-tooltip .tooltip-inner {
    max-width: 300px;
}


.building-ledger-table-container {
    max-height: 687px;
}

.text-red {
    color: #FF0000;
}

.text-green {
    color: #00FF00;
}

.modal-dark .modal-header {
    border-color: #b2873f;
}

.modal-dark .modal-content{
    background-color: #000000;
}

.modal-dark .form-label {
    color: #FFFFFF;
}

.modal-dark .form-control {
    background-color: #FFFFFF;
}

/* Override ApexChart Styles */
.card .apexcharts-menu {
    background-color: #000;
}

.card .apexcharts-menu-icon svg,
.card .apexcharts-reset-icon svg,
.card .apexcharts-zoom-icon svg,
.card .apexcharts-zoomin-icon svg,
.card .apexcharts-zoomout-icon svg {
    fill: #FFFFFF;
}
