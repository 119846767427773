/* Datepicker container */
.react-datepicker-wrapper,
.react-datepicker__input-container {
    display: block;
    width: 100%;
}

/* Input field styling */
.react-datepicker__input-container input {
    width: 100%;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #333; /* Dark border to match the theme */
    background-color: #1c1c1c; /* Dark background to blend with the rest of the UI */
    color: #fff; /* Light text color for contrast */
    border-radius: 4px;
    outline: none;
    transition: border 0.3s ease;
}

/* Hover effect on the input field */
.react-datepicker__input-container input:hover {
    border-color: #666; /* Subtle hover effect for interaction */
}

/* Datepicker calendar popup styling */
.react-datepicker__tab-loop {
    background-color: #1c1c1c; /* Dark background for the datepicker */
    border: 1px solid #333;
    color: #fff;
}

/* Header (Month/Year navigation) */
.react-datepicker__header {
    background-color: #333;
    border-bottom: 1px solid #444;
}

/* Month/Year text in the header */
.react-datepicker__current-month,
.react-datepicker__year-header {
    color: #fff;
    font-weight: bold;
}

/* Day names row */
.react-datepicker__day-names,
.react-datepicker__day-name {
    color: #fff;
}

/* Day cells */
.react-datepicker__day {
    color: #000;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: background-color 0.3s ease, border 0.3s ease;
}

/* Hover effect on day cells */
.react-datepicker__day:hover {
    background-color: #444;
    border-color: #666;
    color: #fff;
}

/* Selected day cell */
.react-datepicker__day--selected {
    background-color: #ffcc00; /* Golden color for selected date */
    color: #000;
    border-color: #ffcc00;
}

/* Today highlighting */
.react-datepicker__day--today {
    color: #000;
}

/* Disabled days */
.react-datepicker__day--disabled {
    color: #666;
    cursor: not-allowed;
}

/* Previous/Next navigation buttons */
.react-datepicker__navigation {
    top: 10px;
    border: none;
    background-color: transparent;
}

.react-datepicker__navigation-icon::before {
    border-color: #fff;
}

/* Month dropdown */
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
    background-color: #1c1c1c;
    color: #fff;
    border: 1px solid #333;
}
