/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    /* .container{display: flex;justify-content: center;align-items: center;} */
    /* .section_height{ height:60vh} */
    .section{background-size: 100% 100%!important;background-repeat: no-repeat!important;}
    .folio_table_box{ height: 51vh;}
    #ownerEnquiry .modal-body{height: 27vh;}
    
    .main_loader {height: 100vh !important;}
    .folio_table_box{height: 88.5vh;}
    .folio_table_box .modal-body{height: 76vh;overflow: auto;}
    ._ledger_table thead tr th:nth-child(2){width: 48%;}
    
    .authorize-section{height: 28vh;}
    .property-list-section{height: 35vh;}
    .personal-section .custom-button{ padding: 0.3rem 1.5rem;}
    ._ledger_table_thead_heading thead tr th{width: 12%;}
    .month_button{padding: 1em 1.38em;}
  .paid_button{padding: 10px 3em;}
  .auto_scroll_bill_due_table {
    height: 30vh;
    overflow: auto;
}
}

/* Portrait */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .menu_link{padding: 10px 0px!important;}
    #ownerEnquiry .modal-body{height: 19vh;}

    ._ledger_table thead tr th:nth-child(2){width: auto;}
    .banking-section{height: 51vh;}
    .table._ledger_table{min-height: 285px;}
    .table._ledger_table tbody{min-height: 240px;}
    .paid_button{padding: 10px 2em;}
}

/* Landscape */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .menu_link{padding: 10px 0px!important;}
    .table._ledger_table{min-height: 285px;}
    .table._ledger_table tbody{min-height: 240px;}
}
/** iPad pro*/
@media only screen and (width: 1024px) and (height: 1366px) {
  .property-ledger-table tbody{ height: 23vh;overflow: auto;}
  .folio_table_box{ height: 51vh;}
  .folio_table_box .modal-body{height: 45vh;overflow: auto;}
  
  .month_button{padding: 1em 1.4em;}
  .paid_button{padding: 10px 27px;}

  ._ledger_table thead tr th:nth-child(2){width: auto;}
  .authorize-section{height: 28vh;}
  .property-list-section{height: 35vh;}
  ._ledger_table_thead_heading thead tr th{width: 12%;}
  ._ledger_table_thead_heading thead tr td{width: 12%;}
}

@media only screen and (width: 820px) and (height: 1180px) {
    .details_box{width: 40%;}
    .modal-header{ margin-top: 0px!important; margin-bottom: 0px!important;}
    .personal-section .modal-footer{ padding: 0px!important;}
    .personal-section .form-group{ margin-bottom: .55rem!important;}
    .personal-section .modal-header .close{ margin: -2rem -1rem -1rem auto!important;}
    .personal-section .authorize-section{height: 45vh!important;}
    .snapshot_details_box{ padding: 20px 14px!important;}
    #ownerEnquiry .modal-body{ height: 24vh!important;}
    .transparent_box .modal-header .close{ margin: 0px!important;}
    .h4, h4{ font-size: 1.2rem!important;}
    .resources-middle{ height: 68vh!important;}
    .folio_table_box{ height: 68vh!important;}
    .billDueTable tbody{height: 31vh;}
    .property-ledger-table tbody{height: 31vh;overflow: auto;display: block;width: 100%;}
    .profile-category p{text-align: center;}
    #ownerEnquiry .modal-body{ height: 20vh;}
    .billDueTable tbody{ height: 23vh;}
    .property-ledger-table tbody{ height: 37vh;overflow: auto;}
    .folio_table_box{ height: 51vh;}
    .main_loader {height: 100vh !important;}
    .details_box{margin: 20px 15px 0px 0px;}
    .paid_button{padding: 10px 27px;}
    .property-ledger-table tbody {height: 23vh;overflow: auto;}
    ._ledger_table thead tr th:nth-child(2){width: auto;}
    .banking-section{height: 51vh;}
    .authorize-section{height: 28vh;}
    .property-list-section{height: 35vh;}
    .auto_scroll_bill_due_table{height: 27vh;}
    .personal-section .custom-button{ padding: 0.3rem 1.5rem;}
    ._ledger_table_thead_heading tbody tr td:nth-child(2){
      width: 55%;
    }
    ._ledger_table_thead_heading tbody tr td:first-child,
    ._ledger_table_thead_heading tbody tr td:nth-child(5),
    ._ledger_table_thead_heading tbody tr td:nth-child(3),
    ._ledger_table_thead_heading tbody tr td:nth-child(4){
      width: 12%;
    }
    .month_button{padding: 1em 1.4em;}
    .folio_table_box .modal-body{height: 60vh;overflow: auto;}
}
@media only screen and (width: 1180px) and (height: 746px) {
    .details_box{width: 40%;}
    .modal-header{ margin-top: 0px!important; margin-bottom: 0px!important;}
    .personal-section .modal-footer{ padding: 0px!important;}
    .personal-section .form-group{ margin-bottom: .55rem!important;}
    .personal-section .modal-header .close{ margin: -2rem -1rem -1rem auto!important;}
    .personal-section .authorize-section{height: 45vh!important;}
    .snapshot_details_box{ padding: 20px 14px!important;}
    #ownerEnquiry .modal-body{ height: 24vh!important;}
    .transparent_box .modal-header .close{ margin: 0px!important;}
    .h4, h4{ font-size: 1.2rem!important;}
    .resources-middle{ height: 68vh!important;}
    .folio_table_box{ height: 68vh!important;}
    .billDueTable tbody{height: 31vh;}
    .property-ledger-table tbody{height: 31vh;overflow: auto;display: block;width: 100%;}
    .profile-category p{text-align: center;}
    #ownerEnquiry .modal-body{ height: 20vh;}
    .billDueTable tbody{ height: 23vh;}
    .property-ledger-table tbody{ height: 37vh;overflow: auto;display: block;}
    .folio_table_box{ height: 51vh;}
    .main_loader {height: 100vh !important;}
    .details_box{margin: 20px 15px 0px 0px;}
    .banking-section{height: 51vh;}
    .authorize-section{height: 28vh;}
    .property-list-section{height: 35vh;}
}
/** iPad mini */
@media only screen and (width: 768px) and (height: 1024px) {
    .details_box{width: 40%;}
    .modal-header{ margin-top: 0px!important; margin-bottom: 0px!important;}
    .personal-section .modal-footer{ padding: 0px!important;}
    .personal-section .form-group{ margin-bottom: .55rem!important;}
    .modal-header .close{ margin: -2rem -1rem -1rem auto!important;}
    .personal-section .authorize-section{height: 45vh!important;}
    .snapshot_details_box{ padding: 20px 14px!important;}
    #ownerEnquiry .modal-body{ height: 24vh!important;}
    .transparent_box .modal-header .close{ margin: 0px!important;}
    .h4, h4{ font-size: 1.2rem!important;}
    .resources-middle{ height: 68vh!important;}
    .folio_table_box{ height: 68vh!important;}
    .billDueTable tbody{height: 31vh;}
    .property-ledger-table tbody{height: 31vh;overflow: auto;display: block;width: 100%;}
    .profile-category p{text-align: center;}
    #ownerEnquiry .modal-body{ height: 20vh;}
    .billDueTable tbody{ height: 23vh;}
    .property-ledger-table tbody{ height: 37vh;overflow: auto;display: block;}
    .folio_table_box{ height: 51vh;}
    .main_loader {height: 100vh !important;}
    .details_box{margin: 20px 15px 0px 0px;}
    .banking-section{height: 51vh;}
    .authorize-section{height: 28vh;}
    .property-list-section{height: 35vh;}
    .auto_scroll_bill_due_table{height: 27vh;}
    .folio_table_box .modal-body{height: 58vh;overflow: auto;}
}
@media only screen and (width: 1024px) and (height: 768px) {
    .details_box{width: 40%;}
    .modal-header{ margin-top: 0px!important; margin-bottom: 0px!important;}
    .personal-section .modal-footer{ padding: 0px!important;}
    .personal-section .form-group{ margin-bottom: .55rem!important;}
    .modal-header .close{ margin: -2rem -1rem -1rem auto!important;}
    .personal-section .authorize-section{height: 45vh!important;}
    .snapshot_details_box{ padding: 20px 14px!important;}
    #ownerEnquiry .modal-body{ height: 24vh!important;}
    .transparent_box .modal-header .close{ margin: 0px!important;}
    .h4, h4{ font-size: 1.2rem!important;}
    .resources-middle{ height: 68vh!important;}
    .billDueTable tbody{height: 31vh;}
    .property-ledger-table tbody{height: 31vh;overflow: auto;display: block;width: 100%;}
    .profile-category p{text-align: center;}
    #ownerEnquiry .modal-body{ height: 20vh;}
    .billDueTable tbody{ height: 23vh;}
    .property-ledger-table tbody{ height: 37vh;overflow: auto;display: block;}
    .folio_table_box{ height: 51vh;overflow: auto;}
    .main_loader {height: 100vh !important;}
    .details_box{margin: 20px 15px 0px 0px;}
    .banking-section{height: 51vh;}
    .authorize-section{height: 28vh;}
    .property-list-section{height: 35vh;}
    .auto_scroll_bill_due_table{height: 27vh;}
}

.section_height{height: 700px!important;}
.plr-20{ padding: 0px 20px;}
.property_details_button div{ color: white;}
.referForm .form-group{ margin-bottom: 2rem!important;}
.with-errors{position: absolute;left: 1rem;bottom: 14px;font-size: 13px;font-style: italic;}
._ledger_table_thead_heading tbody tr td:nth-child(2){
  text-align: left;
}
._ledger_table_thead_heading tbody tr td:nth-child(2){
  width: 55%;
  max-width: 40%;
}
._ledger_table_thead_heading tbody tr td:nth-child(5),
._ledger_table_thead_heading tbody tr td:nth-child(3),
._ledger_table_thead_heading tbody tr td:nth-child(4){
  width: 10%;
}
/** desktop view */
@media only screen and (min-width: 1200px) {
  .month_button{
    /* padding:1em 1.9em */
    width: 30%;
  }
  .tenant-view-table{min-height: 42vh;}

  .auto_scroll_bill_due_table{
    height: 51vh;
    overflow: auto;
  }
}

/** desktop view */
@media only screen and (min-width: 1500px) {
  .auto_scroll_bill_due_table{
    height: 38vh;
    overflow: auto;
  }
  .folio_table_box .modal-body{
      height: 65vh;
      overflow: auto;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-width: 1200px) 
  and (orientation: landscape) {
    .tenant-view-table{min-height: 35vh;}
}
/* Landscape*/

@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)  {
  .paid_button{padding: 10px 2em;width: 46%;}
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    ._ledger_table thead tr th:nth-child(2){width:55%;font-weight:normal;font-size: 10px;}
    ._ledger_table thead tr th:nth-child(3){font-weight:normal;font-size: 10px;padding-left: 0.5px;
padding-right: 0.5px;}
    ._ledger_table thead tr th:nth-child(4){font-weight:normal;font-size: 10px;padding-left: 0.5px;
padding-right: 0.5px;}
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

  ._ledger_table thead tr th:nth-child(2){width:55%;font-weight:normal;font-size: 10px;}
  ._ledger_table thead tr th:nth-child(3){font-weight:normal;font-size: 10px;padding-left: 0.5px;
padding-right: 0.5px;}
    ._ledger_table thead tr th:nth-child(4){font-weight:normal;font-size: 10px;padding-left: 0.5px;
padding-right: 0.5px;}
  
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
._ledger_table thead tr th:nth-child(2){width:55%}
._ledger_table thead tr th:nth-child(3){font-weight:normal;font-size: 10px;padding-left: 0.5px;
padding-right: 0.5px;}
    ._ledger_table thead tr th:nth-child(4){font-weight:normal;font-size: 10px;padding-left: 0.5px;
padding-right: 0.5px;}
}



/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
    ._ledger_table thead tr th:nth-child(2){width:55%}
    ._ledger_table thead tr th:nth-child(3){font-weight:normal;font-size: 10px;padding-left: 0.5px;
    padding-right: 0.5px;}
        ._ledger_table thead tr th:nth-child(4){font-weight:normal;font-size: 10px;padding-left: 0.5px;
    padding-right: 0.5px;}
    .table._ledger_table tbody{min-height: 190px;}
    .table._ledger_table{min-height: 240px;}
    .paid_button {
      padding: 10px 2em;
    }
    .section_height {
      height: 640px!important;
      }
      .section{
        height: 92vh;
      }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    ._ledger_table thead tr th:nth-child(2){width:55%}
    ._ledger_table thead tr th:nth-child(3){font-weight:normal;font-size: 10px;padding-left: 0.5px;
    padding-right: 0.5px;}
        ._ledger_table thead tr th:nth-child(4){font-weight:normal;font-size: 10px;padding-left: 0.5px;
    padding-right: 0.5px;}
    .table._ledger_table tbody{min-height: 190px;}
    .table._ledger_table{min-height: 240px;}
    .paid_button {
      padding: 10px 1em;
    }
    .month_button {
      padding: 10px .6em;
    }
    .section_height {
      height: 640px!important;
      }
      .section{
        height: 92vh;
      }
      .details_box{width: 41%;}
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1112px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    ._ledger_table thead tr th:nth-child(2){width:55%}
    ._ledger_table thead tr th:nth-child(3){font-weight:normal;font-size: 10px;padding-left: 0.5px;
    padding-right: 0.5px;}
        ._ledger_table thead tr th:nth-child(4){font-weight:normal;font-size: 10px;padding-left: 0.5px;
    padding-right: 0.5px;}
    .table._ledger_table tbody{min-height: 190px;}
    .table._ledger_table{min-height: 240px;}
    .paid_button {
      padding: 10px 2em;
    }
    .section_height {
      height: 640px!important;
      }
      .section{
        height: 92vh;
      }
}